import { memo } from "react";
import ReactTooltip from 'react-tooltip';

const DataColumn = memo(({ children, expanding, className, style }) => {

  const allowedTypes = ['string','boolean','number','bigint'];
  const allowTooltip = allowedTypes.includes(typeof(children));
  const divStyle = { whiteSpace: (!expanding) ? 'nowrap' : '', overflow: 'hidden', textOverflow: (allowTooltip) ? 'ellipsis' : 'none' };
  const align = (style && style.align) ? style.align : '';
  const divClass = `item height-100 ${align}`;
  const divTitle = (allowTooltip) ? children : '';
  const reactTool = (allowTooltip) ? <ReactTooltip effect="solid" /> : '';

  return <div title={divTitle} style={{ ...divStyle }} className={`table tableCell ${divClass}  ${className}`}>{reactTool}{children}</div>

})
export default DataColumn


