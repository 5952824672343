
import { Fragment } from 'react';
import { formatDateTime } from 'classes/format';


export default function NoteContainer({ type, data }) {

  return <Fragment>
          <div className={`flex-container row width-100 mar-t10 mar-b10`}>
            <div className={`flex-container row nowrap center width-100`}>
              <div className={`flex-item center width-100 colour background-8 orange text-13`}>{type}</div>
            </div>
            {data.map((x,i) => {
              return <Fragment key={i}>
                <div className={`flex-container row nowrap start width-100 colour background lightGrey`}>
                  <div className={`flex-item start flex-grow-1 mar-r20 text-12`}>{x.createdBy}</div>
                  <div className={`flex-item end mar-r20 text-12`}>{formatDateTime(x.createdAt, "DD-MM-YYYY HH:mm:ss")}</div>
                </div>
                <div className={`flex-container row nowrap start flex-grow-1 width-100 height-100 padding-2 colour background-4 lightGrey mar-b5`}>
                  <div className={`flex-item text-12 pad-8 consumablesHub tonerRobotNotes-text-content`}>{x.eventDetail}</div>
                </div>
              </Fragment>})}
          </div>
        </Fragment>
}
