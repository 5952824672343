import UILIB from 'components';
import { Fragment } from "react";
import { setDrawer } from 'store/actions';
import moment from "moment";
import Modules from './Modules';


export function setFilterVarsRequest(filter) {
  if (filter.button === 'Unactioned') return ' status eq 0 and requestPending eq 0 ';
  if (filter.button === 'Pending') return ' status eq 0 and requestPending eq 1 ';
  if (filter.button === 'Actioned') return ' status eq 1 and requestPending eq 0 ';
  if (filter.button === 'Deleted') return ' status eq 2 and requestPending eq 0 ';
  return ' ( status eq 0 or status eq 1 or status eq 2 ) and requestPending eq 0 ';
}

export function setFilterVarsRegistration(filter) {
  if (filter.button === 'Unactioned') return ' status eq 0 and isRegistration eq 1 and sentWelcomeEmailPending eq 0 and migrationStatusId ne 3 ';
  if (filter.button === 'Pending') return ' status eq 1 and isRegistration eq 1 and sentWelcomeEmailPending eq 1 and migrationStatusId ne 3 ';
  if (filter.button === 'Actioned') return ' status eq 1 and sentWelcomeEmail eq 1 and migrationStatusId ne 3 ';
  if (filter.button === 'Deleted') return ' status eq 2 and isRegistration eq 1 and sentWelcomeEmailPending eq 0 and migrationStatusId ne 3 ';
  return ' ( ( status eq 0 or status eq 1 or status eq 2 ) and isRegistration eq 1 ) or ( status eq 1 and sentWelcomeEmail eq 1 ) and migrationStatusId ne 3 ';
}

export function setFilterVarsGuardian(filter) {
  if (filter.button === 'Unactioned') return ' guardianStatus eq 0 ';
  if (filter.button === 'Actioned') return ' guardianStatus eq 1 ';
  if (filter.button === 'Deleted') return ' guardianStatus eq 2 ';
  return ' guardianStatus eq 0 or guardianStatus eq 1 or guardianStatus eq 2 ';
}

export function setFilterVarsUser(selectedCustomer, filter) {

  const active = (filter.button === 'Active') ? ' status eq 1 and isRegistration eq 0  and migrationStatusId ne 3 and isActive eq 1 ' : null;
  const disabled = (filter.button === 'Disabled') ? ' status eq 1 and isRegistration eq 0  and migrationStatusId ne 3 and isActive eq 0 ' : null;
  const deleted = (filter.button === 'Deleted') ? ' status eq 2 and isRegistration eq 0  and migrationStatusId ne 3 ' : null;
  const selected = (selectedCustomer.selected) ? ` customerId eq ${selectedCustomer.customerId} and isRegistration eq 0 and migrationStatusId ne 3 ` : null;
  
  const arrayData = [active, disabled, deleted, selected].filter(x => x);

  if(arrayData.length) return arrayData.join(' and ');
  return ' ( status eq 1 or status eq 2 ) and isRegistration eq 0 and migrationStatusId ne 3 ';
}

export function setFilterVarsCustomer(filter) {
  if (filter.button === 'Active') return ' customerDeleted eq 0 and isRegistration eq 0 ';
  if (filter.button === 'Deleted') return ' customerDeleted eq 1 and isRegistration eq 0 ';
  return ' ( customerDeleted eq 0 or customerDeleted eq 1 ) and isRegistration eq 0 ';
}

export function setFilterVarsMigration(isMigratedView) {
  const notMigrated = (!isMigratedView) ? ' migrationStatusId eq 1 and deletedAt is null ' : null;
  const migrated = (isMigratedView) ? ' migrationStatusId eq 2 and deletedAt is null ' : null;
  
  const arrayData = [notMigrated, migrated].filter(x => x);

  if(arrayData.length) return arrayData.join(' and ');
  return '';
}

export function getYesNo() {
  return [{ value: 0, label: "Select" }, { value: 1, label: "No" }, { value: 2, label: "Yes" }]
}

export function tableContainer(onClick, icon, toolTip, colour, hover) {
  return <UILIB.TableContainer onClick={onClick} icon={icon} toolTip={toolTip} colour={colour} hover={hover} />
}

export function getGridChip(type, value, additional) {
  if (type === 'regStatus' && value === 0 && Number(additional) === 0) return <UILIB.Chip toolTip={'Unactioned'} className="table colour background-8 orange text-small text-black width-100" value="Unactioned" />
  if (type === 'regStatus' && value === 1 && Number(additional) === 1) return <UILIB.Chip toolTip={'Pending'} className="table colour background-8 yellow text-small text-black width-100" value="Pending" />
  if (type === 'regStatus' && value === 1 && Number(additional) === 0) return <UILIB.Chip toolTip={'Actioned'} className="table colour background-8 green text-small text-black width-100" value="Actioned" />
  if (type === 'regStatus' && value === 2 && Number(additional) === 0) return <UILIB.Chip toolTip={'Deleted'} className="table colour background-8 red text-small text-white width-100" value="Deleted" />
  if (type === 'requestStatus' && value === 0 && additional === 0) return <UILIB.Chip toolTip={'Unactioned'} className="table colour background-8 orange text-small text-black width-100" value="Unactioned" />
  if (type === 'requestStatus' && value === 0 && additional === 1) return <UILIB.Chip toolTip={'Pending'} className="table colour background-8 yellow text-small text-black width-100" value="Pending" />
  if (type === 'requestStatus' && value === 1 && additional === 0) return <UILIB.Chip toolTip={'Actioned'} className="table colour background-8 green text-small text-black width-100" value="Actioned" />
  if (type === 'requestStatus' && value === 2 && additional === 0) return <UILIB.Chip toolTip={'Deleted'} className="table colour background-8 red text-small text-white width-100" value="Deleted" />
  if (type === 'isRequest' && Boolean(value)) return <UILIB.Chip toolTip={'Yes'} className="table colour background-8 green text-small text-black width-100" value="Yes" />
  if (type === 'isRequest' && !Boolean(value)) return <UILIB.Chip toolTip={'No'} className="table colour background-8 orange text-small text-black width-100" value="No" />
  if (type === 'type' && value === 0) return <UILIB.Chip toolTip={'Default'} className="table colour background-8 green text-small text-black width-100" value="Default" />
  if (type === 'type' && value === 1) return <UILIB.Chip toolTip={'Portals'} className="table colour background-8 orange text-small text-black width-100" value="Portals" />
  if (type === 'status' && value === 0) return <UILIB.Chip toolTip={'New'} className="table colour background-8 green text-small width-100" value="New" />
  if (type === 'status' && value === 1) return <UILIB.Chip toolTip={'Processing'} className="table colour background-8 orange text-small text-black width-100" value="Processing" />
  if (type === 'status' && value === 2) return <UILIB.Chip toolTip={'Error'} className="table colour background-8 red text-small text-black width-100" value="Error" />
  if (type === 'status' && value === 3) return <UILIB.Chip toolTip={'Success'} className="table colour background-8 darkGreen text-small text-black width-100" value="Success" />
  if (type === 'admin' && value === 0) return <UILIB.Chip toolTip={'User'} className="table colour background-8 pineGreen text-small text-black width-100" value="User" />
  if (type === 'admin' && value === 1) return <UILIB.Chip toolTip={'Admin'} className="table colour background-8 novemberOrange text-small text-black width-100" value="Admin" />
  if (type === 'admin' && value === 2) return <UILIB.Chip toolTip={'Owner'} className="table colour background-8 purple text-small text-black width-100" value="Owner" />
  if (type === 'user' && (value === 1 && additional)) return <UILIB.Chip toolTip={'Active'} className="table colour background-8 green text-small text-black width-100" value="Active" />
  if (type === 'user' && ((value === 1 && !additional) || value === 0)) return <UILIB.Chip toolTip={'Disabled'} className="table colour background-8 orange text-small text-black width-100" value="Disabled" />
  if (type === 'user' && value === 2) return <UILIB.Chip toolTip={'Deleted'} className="table colour background-8 red text-small text-black width-100" value="Deleted" />
  if (type === 'customer' && value === 0) return <UILIB.Chip toolTip={'Active'} className="table colour background-8 green text-small text-black width-100" value="Active" />
  if (type === 'customer' && value === 1) return <UILIB.Chip toolTip={'Deleted'} className="table colour background-8 red text-small text-black width-100" value="Deleted" />
  if (type === 'requestSource' && value === 'Hub') return <UILIB.Chip toolTip={value} className="table colour background-8 fordBlue text-small text-white width-100" value={value} />
  if (type === 'requestSource' && value === 'Deal') return <UILIB.Chip toolTip={value} className="table colour background-8 royalBlue text-small text-white width-100" value={value} />
  if (type === 'createSource' && value === 'Hub') return <UILIB.Chip toolTip={value} className="table colour background-8 fordBlue text-small text-white width-100" value={value} />
  if (type === 'createSource' && value === 'VO') return <UILIB.Chip toolTip={value} className="table colour background-8 azureBlue text-small text-black width-100" value={value} />
  if (type === 'createSource' && value === 'Portal') return <UILIB.Chip toolTip={value} className="table colour background-8 royalBlue text-small text-white width-100" value={value} />
  if (type === 'createSource' && value === 'Migration') return <UILIB.Chip toolTip={value} className="table colour background-8 azureBlue text-small text-white width-100" value={value} />
  if (type === 'createSource' && value === 'Internal') return <UILIB.Chip toolTip={value} className="table colour background-8 pineGreen text-small text-white width-100" value={value} />
  if (type === 'createGrading' && value === 'Bronze') return <UILIB.Chip toolTip={value} className="table colour background gradingBronze text-small text-white width-100" value={value} />
  if (type === 'createGrading' && value === 'Silver') return <UILIB.Chip toolTip={value} className="table colour background gradingSilver text-small text-black width-100" value={value} />
  if (type === 'createGrading' && value === 'Gold') return <UILIB.Chip toolTip={value} className="table colour background gradingGold text-small text-black width-100" value={value} />
  if (type === 'createGrading' && value === 'Platinum') return <UILIB.Chip toolTip={value} className="table colour background gradingPlatinum text-small text-black width-100" value={value} />
  if (type === 'createGrading' && value === 'Diamond') return <UILIB.Chip toolTip={value} className="table colour background gradingDiamond text-small text-black width-100" value={value} />
  if (type === 'inviteStatus' && value === 'Pending') return <UILIB.Chip toolTip={value} className="table colour background-8 orange text-small text-black width-100" value={value} />
  if (type === 'inviteStatus' && value === 'Accepted') return <UILIB.Chip toolTip={value} className="table colour background-8 green text-small text-black width-100" value={value} />
}

export function actionOpenDrawer(type, entity, dispatch, enabled, sync, width) {
  if (!enabled) return false;
  const selector = (type, data) => {
    if (type === 'RequestPortalDrawer') return <Modules.RequestPortalDrawer entity={data} onClose={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} />
    if (type === 'ReportDrawer') return <Modules.ReportDrawer entity={data} onClose={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} />
    if (type === 'EditUserDrawer') return <Modules.EditUserDrawer entity={data} onClose={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} />
    if (type === 'EditRequestDrawer') return <Modules.EditRequestDrawer entity={data} onClose={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} />
    if (type === 'CustomerDrawerEvent') return <Modules.CustomerDrawerEvent entity={data} onClose={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} />
    if (type === 'ViewNotesDrawer') return <Modules.ViewNotesDrawer entity={data} onClose={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} />
    if (type === 'ViewAuditDrawer') return <Modules.ViewAuditDrawer entity={data} onClose={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} />
    if (type === 'EditCustomerDrawer') return <Modules.EditCustomerDrawer entity={data} onClose={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} />
    if (type === 'AuditSheetDrawer') return <Modules.AuditDrawer entity={data} onClose={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} />
  };

  dispatch(setDrawer({
    open: true,
    width: (width) ? width : "550px",
    content: selector(type, entity)
  }))
}

export function getFlatNotes(row) {
  return row.notes.map(x => { return ` [${moment(x.createdAt).utc().format('YYYY-MM-DD')}:${x.createdByTxt}] -> ${x.theNotes} ` }).join(' | ');
}

export function getEmptyFormObject(azureId = null, serviceRegion = null) {
  return { 
    customerFirstName: '',
    customerLastName: '',
    customerEmail: '',
    companyName: '',
    accountNumber: '',
    notes: null,
    customerId: null,
    status: 0,
    requestPending: 0,
    requestTypeId: 3,
    autoGenerated: 1,
    isAdmin: 0,
    serviceRegion: serviceRegion,
    requestedAt: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
    requestedBy: azureId }
}

export function getRequestDrawerObject(entity) {
  return { 
    customerId: entity.customerId,
    companyName: entity.companyName,
    customerFirstName: entity.customerFirstName, 
    customerLastName: entity.customerLastName, 
    customerTelephoneNo: entity.customerTelephoneNo, 
    specialRequirements: entity.specialRequirements,
    IsMeter: entity.IsMeter,
    IsWork: entity.IsWork,
    IsUnsubscribed: entity.IsUnsubscribed,
    IsLiveLink: entity.IsLiveLink,
    IsActive: entity.IsActive,
    IsPrimary: entity.IsPrimary,
    IsInvoice: entity.IsInvoice }
}

export function getCustomerDrawerObject(entity) {
  return { 
    customer: entity,
    userBase: [],
    existingOwner: undefined,
    userBaseSelected: -1,
    context: -1,
    userActionSelected: -1,
    confirm: false,
    firstName: '',
    lastName: '',
    emailAddress: ''
  }
}

export function getCustomerDrawerBaseObject() {
  return { 
    userBaseSelected: -1,
    context: -1,
    userActionSelected: -1,
    confirm: false,
    firstName: '',
    lastName: '',
    emailAddress: ''
  }
}

export function getUtilTableDefaults() {
  return {
    expanding: false,
    filtering: false,
    sortable: true,
    caching: false,
    filterEnabled: false, 
    refreshEnabled: false,
    paginationEnabled: false,
    showRecords: false,
    exportEnabled: false,
    expandEnabled: false,
    deleteEnabled: false
  }
}

export function getAuditFilters() {
  return { 
    workflowIds: [],
    sourceIds: [],
    userIds: undefined,
    datesEvent: {
      startDate: moment().subtract(1,"year").utc().format('YYYY-MM-DD').toString(),
      endDate: moment().utc().format('YYYY-MM-DD').toString(),
      disabled: true
    }
  };
}

export function getBGColour(row) {
  if (Number(row.customerId) !== 0) return '';
  if (Number(row.customerId) === 0) return 'colour background-2 red';
  return '';
}

export function getDefaultReportFilters() {
  return { 
    startDate: moment().subtract(11,"months").startOf('month').utc().format('YYYY-MM-DD'),
    endDate: moment().endOf('month').utc().format('YYYY-MM-DD')
  }
}

export function getSearchMessage(response) {
  if(response?.hasPortal) return { formEnable: false, error: true, message: `This customer already has a portal on the ${response.databaseName} database, the admin user is ${response.firstName} ${response.lastName}` };
  if(response?.hasRequest) return { formEnable: false, error: true, message: `This customer has already got an open portal request, requested by ${response.requestedBy}` };
  return { formEnable: true, error: true, message: "This customer does not currently have a portal, please use the form below to request one" };
}

export function getRequestMessage(response) {
  if(response?.userExists) return { error: true, message: `This email address is already registered as a portal user for ${response.customerName}` };
  if(response?.userRequestExists) return { error: true, message: `This email address is already registered against a portal request for ${response.customerName}` };
  return { error: false, message: '' };
}

export function getToastMessage(row, type) {
  if(type === 0) return `Welcome Email Successfully sent for: ${row.customerName}`
  if(type === 1) return `Welcome Email Successfully sent for: ${row.customerName}`
  if(type === 2) return `Password Reset Email Successfully sent for: ${row.firstName} ${row.lastName}`
  if(type === 3) return `Request Complete for: ${row.companyName}`
  return '';
}

export function getProcessButtonType(row) {
  if(Number(row.status) === 0) return { class: "table text-small colour background grey width-100", text: "Process" };
  if(Number(row.status) === 1) return { class: "table colour background orange text-small text-black width-100", text: "Processed" };
  if(Number(row.status) === 2) return { class: "table text-small colour background red width-100" , text: "Unavailable"  };
  return '';
}

export function clearUserFilter(defaultSelected, setSelectedCustomer, remoteRender, setRemoteRender) {
  return <UILIB.Link onClick={() => { setSelectedCustomer(defaultSelected); setRemoteRender(!remoteRender) }} className="mar-l5">Click Here</UILIB.Link>;
}

export function findLongestArray(data = [], key) {
  return data.reduce((prev, current) => {
    if (current[key].length > prev[key].length) {
      return current;
    } else {
      return prev;
    }
  });
}

export function SectionHeader({ customerName, section, smallLogoBase64 }) {
  return (
    <div className="tw-flex tw-items-center tw-justify-between tw-bg-ag-default">
      <h1 className="tw-pl-4 tw-text-white tw-text-xl">
        {customerName} - {section}
      </h1>
      <img
        src={`data:image/svg+xml;base64,${decodeURIComponent(smallLogoBase64)}`}
        style={{ height: 64, width: 64, padding: 8 }}
        alt="Agilico Logo"
      />
    </div>
  );
}

export function getBarGraphOptions() {
  return {
    type: "bar",
    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: true,
        maxWidth: 20, 
        labels: {
          boxWidth: 20
        }
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
          drawBorder: true,
        },
      },
      y: {
        ticks: {
          display: true,
        },
        grid: {
          display: true,
          drawBorder: true,
        },
      },
    }
  }
}

export function MigrationUserContainer(row, viewCustomer, userType) {
  return <Fragment>
    <div className={`flex-container start row width-100 height-100`} >
      <div className="flex-item start flex-grow-1">
        <div className="flex-container column width-100">
          <div className="flex-item row width-100 colour">
            <p className="flex-item start width-60-px text-11 pad-l5 pad-r20">Name:</p>
            <p className="flex-item start text-11">{`${row.firstName} ${row.lastName}`}</p>
          </div>
          <div className="flex-item row width-100 colour">
            <p className="flex-item start width-60-px text-11 pad-l5 pad-r20">Email:</p>
            <p className="flex-item start text-11">{row.emailAddress}</p>
          </div>
        </div>
      </div>
      <div className="flex-item end center align-center height-80 width-10 pad-2">
        {userType}
      </div>
      <div className="flex-item end center align-center height-80 width-10 pad-2">
        {viewCustomer}
      </div>
    </div>
  </Fragment>
}

