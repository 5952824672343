import UILIB from 'components';
import moment from "moment";
import { setDrawer } from 'store/actions';
import { purchaseTypes } from 'classes/quotesHelpers';
import { formatCurrency } from 'classes/format';
import Modules from './Modules';


export function setAwaitingFilterVars(filters) {

  const baseQuery = '( approved eq 0 and archived eq 0 and status eq 1 )';
  const regions = (filters.regionIds.length) ? ` salesRegion in ${filters.regionIds.join(',')} ` : null;
  const staff = (filters.staff !== -1) ? ` activeDirectoryObjectGuid eq ${filters.sopStaff} ` : null;

  const slaButton = (filters.slaButton !== '') 
    ? (filters.slaButton === 'NearSLA') 
      ? ` ( slaUnapproved gte 7 and slaUnapproved lte 10 ) ` 
      : ` ( slaUnapproved gt 10 ) ` 
    : null;

  const arrayData = [baseQuery, regions, staff, slaButton].filter(x => x);

  return arrayData.join(' and ');
}

export function setApprovedFilterVars(filters) {

  const datesApproved = (!filters.datesApproved.disabled) ? ` ( approvalDate gte ${moment(filters.datesApproved.startDate).utc().format('YYYY-MM-DD 00:00')} and approvalDate lte ${moment(filters.datesApproved.endDate).utc().format('YYYY-MM-DD 23:59')} ) ` : null;
  const datesInvoiced = (!filters.datesInvoiced.disabled) ? ` ( invoiceDate gte ${moment(filters.datesInvoiced.startDate).utc().format('YYYY-MM-DD 00:00')} and invoiceDate lte ${moment(filters.datesInvoiced.endDate).utc().format('YYYY-MM-DD 23:59')} ) ` : null;
  
  const regions = (filters.regionIds.length) ? ` salesRegion in ${filters.regionIds.join(',')} ` : null;
  const logistics = (filters.logistics !== -1) ? ` engStatus eq ${filters.logistics} ` : null;
  const staff = (filters.staff !== -1) ? ` activeDirectoryObjectGuid eq ${filters.staff} ` : null;
  const sopStaff = (filters.sopStaff !== -1) ? ` activeDirectoryObjectGuidSopOwner eq ${filters.sopStaff} ` : null;

  const slaButton = (filters.slaButton !== '') 
    ? (filters.slaButton === 'NearSLA') 
      ? ` ( slaApproved gte 7 and slaApproved lte 10 ) ` 
      : ` ( slaApproved gt 10 ) ` 
    : null;

  const arrayData = [datesApproved, datesInvoiced, regions, logistics, staff, sopStaff, slaButton].filter(x => x);

  if(filters.button === 'Active') {
    return [...arrayData, ' ( approved eq 1 and status eq 1 and archived eq 0 ) '].join(' and ');
  }
  if(filters.button === 'Archived') {
    return [...arrayData, ' ( approved eq 1 and status eq 1 and archived eq 1 ) '].join(' and ');
  }
  return [...arrayData, ' ( approved eq 1 and status eq 1 and ( archived eq 0 or archived eq 1 ) ) '].join(' and ');
}

export function getDefaultFilters(location) {
  return { 
    button: ((location && String(location.pathname).toLowerCase() === '/sophub') || !location) ? 'Active' : null,
    slaButton: '', 
    regionIds: [], 
    logistics: -1, 
    staff: -1, 
    sopStaff: -1,
    datesApproved: {
      startDate: moment().startOf('month').utc().format('YYYY-MM-DD').toString(),
      endDate: moment().utc().format('YYYY-MM-DD').toString(),
      disabled: true
    },
    datesInvoiced: {
      startDate: moment().startOf('month').utc().format('YYYY-MM-DD').toString(),
      endDate: moment().utc().format('YYYY-MM-DD').toString(),
      disabled: true
    }
  };
}

export function getDefaultReportFilters() {
  return { 
    masterDeal: {
      regionIds: [],
      workflowIds: [],
      staff: -1, 
      paymentType: -1,
      datesEvent: {
        eventDateStart: moment().startOf('month').utc().format('YYYY-MM-DD').toString(),
        eventDateEnd: moment().utc().format('YYYY-MM-DD').toString(),
        disabled: true
      },
      datesOrdered: {
        orderDateStart: moment().startOf('month').utc().format('YYYY-MM-DD').toString(),
        orderDateEnd: moment().utc().format('YYYY-MM-DD').toString(),
        disabled: true
      },
      datesInvoiced: {
        invoiceDateStart: moment().startOf('month').utc().format('YYYY-MM-DD').toString(),
        invoiceDateEnd: moment().utc().format('YYYY-MM-DD').toString(),
        disabled: true
      },
      datesPaid: {
        paidDateStart: moment().startOf('month').utc().format('YYYY-MM-DD').toString(),
        paidDateEnd: moment().utc().format('YYYY-MM-DD').toString(),
        disabled: true
      }
    }
  };
}

export function getEmptySendLogisticsObject() {
  return {
    deal: {},
    productCount: 0,
    warehouse: -1,
    warehouses: [],
    addresses: [],
    devices: [] }
}

export function getYesNo() {
  return [{ value: -1, label: "Select" },{ value: 0, label: "No" },{ value: 1, label: "Yes" }];
}

export function getPaymentType() {
  return [{ value: -1, label: "All Payment Types" }, ...purchaseTypes.map(x => { return { value: x, label: x } })];
}

export function tableContainer(onClick, icon, toolTip, colour, hover) {
  return <UILIB.TableContainer onClick={onClick} icon={icon} toolTip={toolTip} colour={colour} hover={hover} />
}

export function actionOpenDrawer(type, entity, dispatch, enabled, sync, width) {
  if(!enabled) return false;
  const selector = (type, data) => {
    if(type === 'ViewNotesDrawer') return <Modules.NotesDrawer entity={entity} allowAdd={'/sopHub'} onClose={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} /> 
    if(type === 'SendToLogisticsDrawer') return <Modules.SendToLogistics deal={entity} isDisabled={false} onFinished={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} />
    if(type === 'EditSopDrawer') return <Modules.SopDrawer deal={entity} onFinished={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} />
    if(type === 'AwaitingFiltersDrawer') return <Modules.AwaitingFiltersDrawer data={entity} onFinished={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} />
    if(type === 'ApprovedFiltersDrawer') return <Modules.ApprovedFiltersDrawer data={entity} onFinished={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} />
    if(type === 'MasterDealSummarySheet') return <Modules.MasterDealDrawer data={entity} onFinished={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} />
  };
  dispatch(setDrawer({ 
    open: true, 
    width: width,
    content: selector(type, entity)
  }))
}

export function getStatusChip(row) {
  if (row.sopStatus === 'Awaiting Invoice') {
    return <UILIB.Chip toolTip="Awaiting Invoice" className="flex-container wrap table colour background-8 orange text-small width-100" value="Awaiting Invoice" />;
  }
  if (row.sopStatus === 'Invoiced') {
    return <UILIB.Chip toolTip="Invoiced" className="table colour background-8 green text-small width-100" value="Invoiced" />;
  }
  if (row.sopStatus === 'Archived') {  
    return <UILIB.Chip toolTip="Archived" className="table colour background-8 purple text-small width-100" value="Archived" />;
  }
  return <UILIB.Chip toolTip="Active" className="table colour background-8 grey text-small width-100" value="Active" />;  
}

export function calcResetEnable(filters, defaultfilters) {
  return Boolean(filters.button === 'Active' && JSON.stringify(defaultfilters) === JSON.stringify({ ...filters, queryString: null }));
};

export function getAwaitingRowColour(row) {
  if (row.slaUnapproved >= 4 && row.slaUnapproved <= 8) {
    return 'colour background-2 orange'
  }
  if (row.slaUnapproved > 8) {
    return 'colour background-2 red'
  }
}

export function getApprovedRowColour(row) {
  if (row.slaApproved >= 4 && row.slaApproved <= 10) {
    return 'colour background-2 orange'
  }
  if(row.slaApproved > 10) {
    return 'colour background-2 red'
  }
}

export function getNotesColour(row) {
  if (!row.dealNote.length) {
    return 'colour lightGrey'
  }
  if(row.dealNote.some(x => (x.noteSource.trim() === 'Internal Notes' || x.noteSource.trim() === 'External Notes')) && !row.dealNote.some(x => x.noteSource.trim() === 'General Notes')) {
    return 'colour background-6 orange'
  }
  if(row.dealNote.some(x => x.noteSource.trim() === 'General Notes')) {
    return 'colour background-6 red'
  }
}

export function getGp(row) {
  if(!row.pricing) return <div></div>;
  return <div>
    <div className="flex-container center row nowrap width-100 height-100">
        <div className="flex-item start">GP:</div>
        <div className="flex-item end flex-grow-1">{formatCurrency(row.pricing.gp)}</div>
    </div>
  </div>
}

export function getInvoice(row) {
  if(!row.pricing) return <div></div>;
  return <div>
    <div className="flex-container center row nowrap width-100 height-100">
        <div className="flex-item end flex-grow-1">{formatCurrency(row.pricing.total)}</div>
    </div>
  </div>
}

export function getTabContext(tab) {
  if(tab === 1) return 'sopAwaiting';
  if(tab === 2) return 'sopApproved';
}

export function getLogisticsStatus(value) {
  const statusObj = {
    0: 'None',
    1: 'Awaiting Refurb',
    2: 'Parts on Order',
    3: 'In build progress',
    4: 'On Hold',
    5: 'Install Date Agreed',
    6: 'Awaiting Network Form',
    7: 'Software',
    10: 'Completed',
    15: 'Build Complete',
    25: 'Invoice Only',
    30: 'All stock in - Awaiting Build' }
  if(!value) return 'None';
  return statusObj[value];
}





